import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const OffRoadButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(2, 3),
    fontSize: theme.spacing(2),
    lineHeight: theme.spacing(3),
  },
}));

export default OffRoadButton;
