import React from 'react';
import StyledBox, {
  StyledSliderSectionContiner,
  StyledImgBox,
} from 'views/OffRoadToursLandingView/components/TestimonialsSection/TestimonialsSection.styles';
import { Box, Container, Typography } from '@mui/material';
import { ImgBox, Typography700 } from 'views/common/components';
import { useTranslation } from 'next-i18next';
import Comment from 'views/common/components/UI/Icons/Comment';
import Typography500 from 'views/common/components/UI/Typography500';

const TestimonialsSection = () => {
  const { t } = useTranslation('fe_er_off_road_tours_landing_page');

  const testimonials = [
    {
      id: 1,
      title: t('fe_er_off_road_tours_landing_page:testimonials_title_1'),
      comment: t('fe_er_off_road_tours_landing_page:testimonials_comment_1'),
      name: t('fe_er_off_road_tours_landing_page:testimonials_name_1'),
    },
    {
      id: 2,
      title: t('fe_er_off_road_tours_landing_page:testimonials_title_2'),
      comment: t('fe_er_off_road_tours_landing_page:testimonials_comment_2'),
      name: t('fe_er_off_road_tours_landing_page:testimonials_name_2'),
    },
    {
      id: 3,
      title: t('fe_er_off_road_tours_landing_page:testimonials_title_3'),
      comment: t('fe_er_off_road_tours_landing_page:testimonials_comment_3'),
      name: t('fe_er_off_road_tours_landing_page:testimonials_name_3'),
    },
  ];

  return (
    <StyledBox>
      <Container maxWidth="xl">
        <Typography component="p" variant="h5" className="TSTitle">
          {t('fe_er_off_road_tours_landing_page:testimonials')}
        </Typography>
        <Typography700 component="p" variant="h2" className="TSSubTitle">
          {t(
            'fe_er_off_road_tours_landing_page:what_your_fellow_adventurers_say',
          )}
        </Typography700>
        <StyledSliderSectionContiner
          mobilePartialVisibilityGutter={50}
          deskTopItems={3}
          carouselArrow={false}>
          {testimonials.map((item) => (
            <Box className="CarouselItem" key={item.id}>
              <Box className="TSTestimonialsCard">
                <Box className="TSTestimonialsText">
                  <Comment className="TSCommentIcon" />
                  <Typography500
                    component="p"
                    variant="h5"
                    className="TSCommentTitle">
                    {item.title}
                  </Typography500>
                  <Typography
                    component="p"
                    variant="body1"
                    className="TSCommentDesc">
                    {item.comment}
                  </Typography>
                  <Box className="TStriangle" />
                </Box>
                <Box className="TSTestimonialsUser">
                  <Box className="TSUserImg">
                    <ImgBox
                      image={{
                        src: item.img || '/imgs/off-road/user.png',
                        alt: 'back-ground',
                      }}
                      imgHeight="100%"
                      imgWidth="100%"
                    />
                  </Box>
                  <Typography500
                    component="p"
                    variant="h5"
                    className="TSUserName">
                    {item.name}
                  </Typography500>
                </Box>
              </Box>
            </Box>
          ))}
        </StyledSliderSectionContiner>
      </Container>
      <Box className="TSSectionBackground">
        <StyledImgBox
          image={{
            src: '/imgs/off-road/offroad-bg.png',
            alt: 'back-ground',
          }}
          imgHeight="100%"
          imgWidth="100%"
        />
      </Box>
    </StyledBox>
  );
};

export { TestimonialsSection };
