import React from 'react';
import StyledBox, {
  StyledSliderSectionContiner,
} from 'views/OffRoadToursLandingView/components/ExperienceTourSection/ExperienceTourSection.styles';
import { useTranslation } from 'next-i18next';
import {
  Box, Container, Grid, Typography,
} from '@mui/material';
import { ImgBox } from 'views/common/components';
import { useSelector } from 'react-redux';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import OffRoadTitle from 'views/OffRoadToursLandingView/components/OffRoadTitle.styles';
import { BookingBtn } from 'views/OffRoadToursLandingView/components';
import moneyFormat from 'lib/utils/moneyFormat';
import Dinero from 'lib/utils/Dinero';
import { offRoadTourPath } from 'views/OffRoadToursLandingView/utils/helpers';

const ExperienceTourSection = (props) => {
  const { t } = useTranslation('fe_er_off_road_tours_landing_page');

  const { tour } = props;
  const { deviceType } = useSelector((state) => state.globalData);
  const gridBG = {
    src: '/imgs/off-road/ex-bg.png',
    alt: 'back-ground',
  };

  return (
    <StyledBox>
      <Container maxWidth="xl" className="">
        <Grid
          container
          columnSpacing={{ lg: 4, md: 0 }}
          rowSpacing={2}
          className="ESContent">
          <Grid item xs={12} md={12} lg={7} className="ETSImgGrid">
            <StyledSliderSectionContiner
              largedesktopItems={1}
              deskTopItems={1}
              tabletItem={1}
              largeDesktopPartialVisibilityGutter={0}
              desktopPartialVisibilityGutter={0}
              tabletPartialVisibilityGutter={0}
              mobilePartialVisibilityGutter={0}
              carouselArrow>
              {tour.images.map((item) => (
                <Box className="CarouselItem" key={item.id}>
                  <Box className="ETSImgGallery">
                    <ImgBox
                      image={{
                        src:
                          deviceType === 'mobile'
                            ? item.medium_url
                            : item.large_url,
                        alt: `${tour.pickup_location.name} - ${t(
                          'fe_er_off_road_tours_landing_page:off_road_adventures',
                        )} - vehicle no. ${item.id}`,
                      }}
                      imgWidth="100%"
                      imgHeight="100%"
                      objectFit="cover"
                    />
                  </Box>
                </Box>
              ))}
            </StyledSliderSectionContiner>
            <Box className="ETSImgesBackground">
              <ImgBox image={gridBG} imgHeight="100%" imgWidth="100%" />
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={5}>
            <OffRoadTitle component="h2" variant="h2">
              {t('fe_er_off_road_tours_landing_page:ready_to_experience')}
            </OffRoadTitle>
            <Box className="ETSLocationAndTime">
              <Box className="ETSLTInfo">
                <AccessTimeIcon className="ETSLTIcon" />
                <Typography
                  component="span"
                  variant="body1"
                  className="ETSLTText">
                  {t('fe_er_off_road_tours_landing_page:dive_time', {
                    hours: tour.duration_in_hours,
                  })}
                </Typography>
              </Box>
            </Box>
            <Typography component="p" variant="body1" className="ETSDesc">
              {t('fe_er_off_road_tours_landing_page:tour_experience_desc')}
            </Typography>
            <BookingBtn
              startPrice={moneyFormat(Dinero(tour.price))}
              btnPath={offRoadTourPath(tour.slug)}
            />
          </Grid>
        </Grid>
      </Container>
      <Box className="ETSSectionBackground">
        <ImgBox
          image={{
            src: '/imgs/off-road/offroad-bg.png',
            alt: 'back-ground',
          }}
          imgHeight="100%"
          imgWidth="100%"
        />
      </Box>
    </StyledBox>
  );
};

export { ExperienceTourSection };
