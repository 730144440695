import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  position: 'relative',
  margin: theme.spacing(8, 0),
  [theme.breakpoints.down('lg')]: {
    margin: theme.spacing(6, 0),
  },
  '& .ORESectionBackground': {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },

  '& .ORECard': {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey.A000,
    height: '100%',
  },
  '& .OREIconContainer': {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.typography.pxToRem(70),
    height: theme.typography.pxToRem(70),
    margin: theme.spacing(1, 0, 4),
  },
  '& .OREIconContainerBG': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(0.75),
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  '& .OREIconContainerBGShadow': {
    backgroundColor: theme.palette.primary.A000,
    borderRadius: theme.spacing(0.75),
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: theme.spacing(-1),
    right: theme.spacing(-1),
    zIndex: 0,
  },
  '& .OREIcon': {
    position: 'relative',
    fontSize: theme.spacing(4),
    zIndex: 2,
  },
  '& .ORETitle': {
    marginBottom: theme.spacing(2),
    color: theme.palette.common.black,
  },
  '& .OREList': {
    padding: theme.spacing(0, 0, 0, 2.75),
  },
  '& .OREListItem': {
    display: 'list-item',
    listStyleType: 'disc',
    padding: theme.spacing(0),
    color: theme.palette.secondary.light,
  },
  '& .OREListText': {
    fontSize: theme.typography.pxToRem(16),
    [theme.breakpoints.down('xl')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  '& .ORENote': {
    marginTop: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.secondary.main,
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
}));

export default StyledBox;
