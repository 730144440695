import React from 'react';
import StyledBox, {
  StyledSliderSectionContiner,
} from 'views/OffRoadToursLandingView/components/MakeModelSection/MakeModelSection.styles';
import { useTranslation } from 'next-i18next';
import {
  Box, Container, Grid, Typography,
} from '@mui/material';
import { Typography700, ImgBox } from 'views/common/components';
import Typography500 from 'views/common/components/UI/Typography500';
import OffRoadButton from 'views/OffRoadToursLandingView/components/OffRoadBtn.styles';
import OffRoadTitle from 'views/OffRoadToursLandingView/components/OffRoadTitle.styles';
import { offRoadTourPath } from 'views/OffRoadToursLandingView/utils/helpers';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import CheckIcon from '@mui/icons-material/Check';

const MakeModelSection = (props) => {
  const { t } = useTranslation('fe_er_off_road_tours_landing_page');
  const { models, tourSlug } = props;
  const options = [
    {
      id: 1,
      text: t('fe_er_off_road_tours_landing_page:option_1'),
    },
    {
      id: 2,
      text: t('fe_er_off_road_tours_landing_page:option_2'),
    },
    {
      id: 3,
      text: t('fe_er_off_road_tours_landing_page:option_3'),
    },
    {
      id: 4,
      text: t('fe_er_off_road_tours_landing_page:option_4'),
    },
  ];
  return (
    <StyledBox>
      <Container maxWidth="xl">
        <OffRoadTitle component="h2" variant="h2">
          {t('fe_er_off_road_tours_landing_page:our_fleet')}
        </OffRoadTitle>
        <Grid container columnSpacing={{ lg: 2, md: 0 }} rowSpacing={3}>
          <Grid item xs={12} md={12} lg={6}>
            <Typography500
              component="p"
              variant="h4"
              className="MMSGrey700"
              mb={3}>
              {t('fe_er_off_road_tours_landing_page:prepare_unparalleled')}
            </Typography500>

            {options.map((option) => (
              <Box key={option.id} className="MMSOption">
                <CheckIcon color="primary" className="MMSOptionIcon" />
                <Typography
                  className="MMSOptionText"
                  component="p"
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: option.text,
                  }}
                />
              </Box>
            ))}
            <Typography700
              component="p"
              variant="h4"
              className="MMSGrey700 MMSNote">
              {t('fe_er_off_road_tours_landing_page:tours_typically_sell_out')}
            </Typography700>
            <OffRoadButton
              variant="contained"
              component={NavigatorLink}
              href={offRoadTourPath(tourSlug)}>
              {t('fe_er_off_road_tours_landing_page:book_now')}
            </OffRoadButton>
          </Grid>
          <Grid item xs={12} md={12} lg={6} className="MMSModelGrid">
            <StyledSliderSectionContiner
              largedesktopItems={2}
              largeDesktopPartialVisibilityGutter={50}
              desktopPartialVisibilityGutter={120}
              deskTopItems={1}
              carouselArrow>
              {models.map((item) => (
                <Box className="CarouselItem" key={item.id}>
                  <Box className="MMSBikeCard">
                    <Box className="MMSImgContainer">
                      <ImgBox
                        image={{
                          src: item.image?.medium_url,
                          alt: item.name,
                        }}
                        imgHeight="100%"
                        imgWidth="100%"
                        imgObjectFit="contain"
                      />
                    </Box>
                    <Box className="MMSTextContainer">
                      <Typography700 component="p" variant="h5">
                        {item.vehicle_make.name}
                      </Typography700>
                      <Typography component="p" variant="body1">
                        {item.name}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </StyledSliderSectionContiner>
          </Grid>
        </Grid>
      </Container>
    </StyledBox>
  );
};

export { MakeModelSection };
