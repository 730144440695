import React from 'react';
import StyledBox from 'views/OffRoadToursLandingView/components/OffRoadExpeditionsSection/OffRoadExpeditionsSection.styles';
import { useTranslation } from 'next-i18next';
import {
  Box,
  Container,
  Grid,
  Typography,
  List,
  ListItem,
} from '@mui/material';
import { ImgBox } from 'views/common/components';
import Bike from 'views/common/components/UI/Icons/offRoad/bike';
import Check from 'views/common/components/UI/Icons/offRoad/check';
import Question from 'views/common/components/UI/Icons/offRoad/question';
import Typography500 from 'views/common/components/UI/Typography500';

const OffRoadExpeditionsSection = () => {
  const { t } = useTranslation('fe_er_off_road_tours_landing_page');
  const expeditions = [
    {
      id: 1,
      title: t('fe_er_off_road_tours_landing_page:experience_title'),
      icon: <Bike className="OREIcon" />,
      items: [
        t('fe_er_off_road_tours_landing_page:experience_item_1'),
        t('fe_er_off_road_tours_landing_page:experience_item_2'),
        t('fe_er_off_road_tours_landing_page:experience_item_3'),
        t('fe_er_off_road_tours_landing_page:experience_item_4'),
        t('fe_er_off_road_tours_landing_page:experience_item_5'),
        t('fe_er_off_road_tours_landing_page:experience_item_6'),
      ],
    },
    {
      id: 2,
      title: t('fe_er_off_road_tours_landing_page:perfect_title'),
      icon: <Check className="OREIcon" />,
      items: [
        t('fe_er_off_road_tours_landing_page:perfect_item_1'),
        t('fe_er_off_road_tours_landing_page:perfect_item_2'),
        t('fe_er_off_road_tours_landing_page:perfect_item_3'),
        t('fe_er_off_road_tours_landing_page:perfect_item_4'),
      ],
    },
    {
      id: 2,
      title: t('fe_er_off_road_tours_landing_page:choose_title'),
      icon: <Question className="OREIcon" />,
      items: [
        t('fe_er_off_road_tours_landing_page:choose_item_1'),
        t('fe_er_off_road_tours_landing_page:choose_item_2'),
        t('fe_er_off_road_tours_landing_page:choose_item_3'),
      ],
    },
  ];
  return (
    <StyledBox>
      <Container maxWidth="xl" className="">
        <Grid container spacing={3}>
          {expeditions.map((expedition) => (
            <Grid item xs={12} md={6} lg={4} key={expedition.id}>
              <Box className="ORECard">
                <Box className="OREIconContainer">
                  {expedition.icon}
                  <Box className="OREIconContainerBG" />
                  <Box className="OREIconContainerBGShadow" />
                </Box>
                <Typography500 variant="h4" component="p" className="ORETitle">
                  {expedition.title}
                </Typography500>
                <List className="OREList">
                  {expedition.items.map((item) => (
                    <ListItem key={item} className="OREListItem">
                      <Typography
                        variant="body1"
                        component="p"
                        className="OREListText">
                        {item}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Typography500 component="p" variant="h4" className="ORENote">
          {t('fe_er_off_road_tours_landing_page:expedition_note')}
        </Typography500>
      </Container>
      <Box className="ORESectionBackground">
        <ImgBox
          image={{
            src: '/imgs/off-road/Expeditionsbg.png',
            alt: 'back-ground',
          }}
          imgHeight="100%"
          imgWidth="100%"
        />
      </Box>
    </StyledBox>
  );
};

export { OffRoadExpeditionsSection };
