import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Comment = (props) => {
  const { className } = props;
  return (
    <SvgIcon
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="21"
      viewBox="0 0 26 21"
      fill="none">
      <path
        d="M25.2891 11.3314H19.3281C19.43 6.55868 20.6622 6.01691 22.2098 5.86227L22.8066 5.781L22.8066 0.532907L22.1187 0.573406C20.0978 0.698315 17.8636 1.09854 16.373 3.12013C15.0663 4.89232 14.4902 7.78759 14.4902 12.2321L14.4902 20.4678H25.2891V11.3314Z"
        fill="#5D5D5D"
      />
      <path
        d="M11.5117 20.4678L11.5117 11.3314H5.63033C5.73223 6.55868 6.92457 6.01691 8.47216 5.86227L9.02923 5.781V0.532907L8.38105 0.573406C6.36018 0.698315 4.10595 1.09854 2.61534 3.12013C1.30881 4.89232 0.712885 7.78759 0.712885 12.2321L0.712885 20.4678H11.5117Z"
        fill="#5D5D5D"
      />
    </SvgIcon>
  );
};

export default Comment;
