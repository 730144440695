import React from 'react';
import StyledBox from 'views/OffRoadToursLandingView/components/HeroSection/HeroSection.styles';
import { useTranslation } from 'next-i18next';
import { Box, Container, IconButton } from '@mui/material';
import { Typography700 } from 'views/common/components';
import Typography500 from 'views/common/components/UI/Typography500';
import PhoneIcon from '@mui/icons-material/Phone';
import OffRoadButton from 'views/OffRoadToursLandingView/components/OffRoadBtn.styles';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import { offRoadTourPath } from 'views/OffRoadToursLandingView/utils/helpers';

const HeroSection = (props) => {
  const { phoneNumber, tourSlug } = props;
  const { t } = useTranslation('fe_er_off_road_tours_landing_page');
  return (
    <StyledBox>
      <Box
        component="video"
        className="HSVideo showLargeDESKTOP"
        autoPlay
        muted
        playsInline
        loop>
        <Box
          component="source"
          src="/videos/offRoadHero.mp4"
          type="video/mp4"
        />
      </Box>
      <Box
        component="video"
        className="HSVideo showMobileAndTabletAndDesktop"
        autoPlay
        muted
        playsInline
        loop>
        <Box
          component="source"
          src="/videos/offRoadHero.webm"
          type="video/webm"
        />
      </Box>
      <Container maxWidth="xl" className="HSContentContainer">
        <Box className="HSContent">
          <Typography700
            component="h1"
            variant="h1"
            className="HSTitle"
            dangerouslySetInnerHTML={{
              __html: t(
                'fe_er_off_road_tours_landing_page:off_road_adventures',
              ),
            }}
          />
          <Typography500 component="p" variant="h3" className="HSSubTitle">
            {t(
              'fe_er_off_road_tours_landing_page:off_road_adventures_sub_title',
            )}
          </Typography500>
          <OffRoadButton
            variant="contained"
            className="ORTLVBtn"
            component={NavigatorLink}
            href={offRoadTourPath(tourSlug)}>
            {t('fe_er_off_road_tours_landing_page:book_your_adventure')}
          </OffRoadButton>
        </Box>
        <Box className="HSPhone" component="a" href={`tel:${phoneNumber}`}>
          <Box className="HSPhoneContainer">
            <Box className="HSPhoneLable">
              <Typography700 component="span" variant="body2">
                {phoneNumber}
              </Typography700>
            </Box>
            <IconButton className="HSPhoneBtn">
              <PhoneIcon />
            </IconButton>
          </Box>
        </Box>
      </Container>
    </StyledBox>
  );
};

export { HeroSection };
