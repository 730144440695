import React from 'react';
import { OffRoadToursLandingView } from 'views/OffRoadToursLandingView';
import { API } from 'lib/config/axios';
import { serverFetch } from 'lib/utils/serverFetch';
import isCrawlers from 'lib/utils/isCrawlers';
import MobileDetect from 'mobile-detect';
import { TOUR_TYPES } from 'lib/utils/CONSTANTS';
import { handleServerResponse } from 'views/tours-module/utils';
import TourView from 'views/tours-module/TourView/TourView';
import { prepareTranslationFiles } from 'views/tours-module/utils/prepareTranslationFiles';
import { defaultNS } from 'next-i18next.config';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { mapNamedLocaleToLocale } from 'lib/data/localesMap';
import generateHreflang from 'lib/utils/generateHreflang';
import PATHS from 'lib/navigation/paths';

const OffRoadTours = (props) => {
  const {
    serverData,
    deviceType,
    ssrRender,
    detectSlugsSSRData,
    bookingDetailsData,
  } = props;

  if (
    detectSlugsSSRData?.slugSSR?.slugs[0].slug_type
    === TOUR_TYPES.GUIDED_OFFROAD
  ) {
    return (
      <TourView
        serverData={serverData}
        deviceType={deviceType}
        ssrRender={ssrRender}
        slugData={detectSlugsSSRData.slugSSR}
        bookingDetailsDataInit={bookingDetailsData}
      />
    );
  }
  return <OffRoadToursLandingView offRoadData={serverData} />;
};

export async function getServerSideProps(ctx) {
  const { locale, query } = ctx;
  const userAgent = ctx.req.headers['user-agent'];

  const ssrRender = isCrawlers(userAgent);
  let serverData = null;
  let layoutData = null;
  let serverError = null;
  let serverResponse = {};
  let translationFiles = [];
  if (query.slug) {
    serverResponse = await handleServerResponse(ctx, TOUR_TYPES.GUIDED_OFFROAD);
    if (serverResponse.detectSlugsSSRData?.newPath) {
      return {
        redirect: {
          destination: serverResponse.detectSlugsSSRData?.newPath,
          permanent: true,
        },
      };
    }
    if (serverResponse.redirectURL) {
      return {
        redirect: {
          destination: serverResponse.redirectURL,
          permanent: true,
        },
      };
    }
    translationFiles = await prepareTranslationFiles(ctx, TOUR_TYPES.GUIDED);
  } else {
    const [layoutRes, offRoadData] = await Promise.all([
      serverFetch(ctx, API.layout, ctx.query),
      serverFetch(ctx, API.offRoadLanding),
    ]);
    translationFiles = {
      ...(await serverSideTranslations(mapNamedLocaleToLocale[locale], [
        ...defaultNS,
        'fe_er_off_road_tours_landing_page',
      ])),
    };
    const hreflangData = generateHreflang(
      PATHS.guidedOffroadTour.index().url,
      ctx.locale,
    );
    serverResponse.hreflangData = hreflangData;
    if (layoutRes.success && layoutRes.data) {
      layoutData = layoutRes.data;
    } else {
      ctx.res.statusCode = 503;
      throw new Error('Rendering halted because we failed to load layout data');
    }
    if (offRoadData.success && offRoadData.data) {
      serverData = offRoadData.data;
    } else {
      serverError = {
        statusCode: offRoadData.error.response.status,
      };
    }
    if (serverError) {
      ctx.res.statusCode = serverError.statusCode;
    }
  }

  let deviceType;
  const md = new MobileDetect(userAgent);
  if (md.tablet()) {
    deviceType = 'tablet';
  } else if (md.mobile()) {
    deviceType = 'mobile';
  } else {
    deviceType = 'desktop';
  }
  return {
    props: {
      ...translationFiles,
      layoutData,
      serverData,
      ssrRender,
      deviceType,
      ...serverResponse,
    },
  };
}
export default OffRoadTours;
