import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .SSImgContainer': {
    width: '100%',
    height: theme.typography.pxToRem(500),
    [theme.breakpoints.down('lg')]: {
      height: theme.typography.pxToRem(200),
    },
  },
}));

export default StyledBox;
