import { Box, alpha } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SliderSectionContiner } from 'views/common/components';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(14, 0),
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(6, 0),
  },
  '& .MMSGrey700': {
    color: theme.palette.grey[700],
  },

  '& .MMSBikeCard': {
    padding: theme.spacing(2, 1),
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[100]}`,
    height: '100%',
    marginBottom: theme.typography.pxToRem(1),
  },
  '& .MMSImgContainer': {
    height: theme.typography.pxToRem(204),
    [theme.breakpoints.down('lg')]: {
      height: theme.typography.pxToRem(180),
    },
  },
  '& .MMSTextContainer': {
    textAlign: 'center',
    marginTop: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
  '& .MMSOption': {
    display: 'flex',
    gap: theme.spacing(0.5),
    alignItems: 'flex-start',
    marginTop: theme.spacing(1),
  },
  '& .MMSOptionIcon': {
    fontSize: theme.spacing(3),
  },
  '& .MMSOptionText': {
    color: theme.palette.grey[600],
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.spacing(1.75),
    },
  },
  '& .MMSNote': {
    margin: theme.spacing(3, 0, 1),
    [theme.breakpoints.down('lg')]: {
      margin: theme.spacing(2, 0, 1),
      fontSize: theme.spacing(1.75),
    },
  },
}));
export const StyledSliderSectionContiner = styled(SliderSectionContiner)(
  ({ theme }) => ({
    '& .SDContiner': {
      marginTop: theme.spacing(1),
    },
    '& .SDSlideItem': {
      cursor: 'pointer',
      width: `${theme.spacing(1.25)}!important`,
      height: `${theme.spacing(1.25)}!important`,
      border: '0 !important',
      backgroundColor: `${theme.palette.grey[100]}!important`,

      '&.active': {
        backgroundColor: `${theme.palette.primary.main}!important`,
      },
    },
    '& .chirldrenWrapper': {
      marginTop: 0,
    },
    '& .CarouselItem': {
      height: '100%',
    },
    '& .react-multiple-carousel__arrow': {
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
      width: theme.spacing(5),
      height: theme.spacing(5),
      minHeight: 'unset',
      minWidth: 'unset',
      backgroundColor: alpha(theme.palette.secondary.dark, 0.9),
      '&.react-multiple-carousel__arrow--right': {
        right: theme.spacing(-2.5),
      },
      '&.react-multiple-carousel__arrow--left': {
        left: theme.spacing(-2.5),
      },
      '&:before': {
        fontWeight: 'bold',
        fontSize: theme.spacing(1.5),
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
  }),
);

export default StyledBox;
