import React from 'react';
import StyledBox from 'views/OffRoadToursLandingView/components/ServicesSection/ServicesSection.styles';
import { Box } from '@mui/material';
import { ImgBox } from 'views/common/components';

const ServicesSection = () => (
  <StyledBox>
    <Box className="showDESKTOP SSImgContainer">
      <ImgBox
        image={{
          src: '/imgs/off-road/ServicesLG.png',
          alt: 'off-road image',
        }}
        imgHeight="100%"
        imgWidth="100%"
        imgObjectFit="cover"
      />
    </Box>
    <Box className="showTABLET SSImgContainer">
      <ImgBox
        image={{
          src: '/imgs/off-road/ServicesMD.png',
          alt: 'off-road image',
        }}
        imgHeight="100%"
        imgWidth="100%"
        imgObjectFit="cover"
      />
    </Box>
  </StyledBox>
);

export { ServicesSection };
