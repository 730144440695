import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import {
  HeroSection,
  ExperienceTourSection,
  BenefitsSection,
  MakeModelSection,
  TestimonialsSection,
  ServicesSection,
  OffRoadExpeditionsSection,
} from 'views/OffRoadToursLandingView/components';
import Head from 'next/head';
import generateMetaData from 'lib/utils/generateMetaData';
import { useDispatch } from 'react-redux';
import { setLeadCategoryId } from 'redux/features/globalData-slice';
import { OFF_ROAD_LEAD_CAT_ID } from 'views/OffRoadToursLandingView/utils/constants';
import { PAGES_GA_IDENTIFIERS } from 'views/common/utils/constants';

const OffRoadToursLandingView = (props) => {
  const { offRoadData } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLeadCategoryId(OFF_ROAD_LEAD_CAT_ID));
    window.gtag('event', 'landing_page_view', {
      page_identifier: PAGES_GA_IDENTIFIERS.OFF_ROAD_LANDING,
    });
    return () => {
      dispatch(setLeadCategoryId(null));
    };
  }, []);

  return (
    <Box>
      <Head>
        {offRoadData.seo_values && generateMetaData(offRoadData.seo_values)}
      </Head>

      <HeroSection
        tourSlug={offRoadData.tour.slug}
        phoneNumber={offRoadData.phone_number}
      />
      <ExperienceTourSection tour={offRoadData.tour} />
      <OffRoadExpeditionsSection />
      <BenefitsSection />
      <MakeModelSection
        models={offRoadData.vehicle_models}
        tourSlug={offRoadData.tour.slug}
      />
      <TestimonialsSection />
      <ServicesSection />
    </Box>
  );
};
export { OffRoadToursLandingView };
