import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const Check = (props) => (
  <SvgIcon
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none">
    <path
      d="M30.1667 6.9065L14.9533 22.1332L9.3 16.4798L11.18 14.5998L14.9533 18.3732L28.2867 5.03984L30.1667 6.9065ZM27.22 13.6265C27.3933 14.3865 27.5 15.1865 27.5 15.9998C27.5 21.8932 22.7267 26.6665 16.8333 26.6665C10.94 26.6665 6.16667 21.8932 6.16667 15.9998C6.16667 10.1065 10.94 5.33317 16.8333 5.33317C18.94 5.33317 20.8867 5.9465 22.54 6.99984L24.46 5.07984C22.3 3.55984 19.6733 2.6665 16.8333 2.6665C9.47333 2.6665 3.5 8.63984 3.5 15.9998C3.5 23.3598 9.47333 29.3332 16.8333 29.3332C24.1933 29.3332 30.1667 23.3598 30.1667 15.9998C30.1667 14.4132 29.8733 12.8932 29.3667 11.4798L27.22 13.6265Z"
      fill="white"
    />
  </SvgIcon>
);

export default Check;
