import { styled } from '@mui/material/styles';
import { Typography700 } from 'views/common/components';

const OffRoadTitle = styled(Typography700)(({ theme }) => ({
  color: theme.palette.common.black,
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('lg')]: {
    marginBottom: theme.spacing(3),
  },
  '&::after': {
    content: "''",
    width: theme.spacing(10),
    height: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.main,
    display: 'block',
    marginTop: theme.spacing(3),
  },
}));

export default OffRoadTitle;
